.App {
  text-align: center;
  min-height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner {
  position: relative;
}

.logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  height: 40%;
}

.banner-img {
  object-fit: cover;
  height: 250px;
  width: 100%;
}

.img-text {
  position: absolute;
  color: white;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-container {
  margin: 15px;
  margin-bottom: 80px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  max-height: fit-content;
}

.card {
  display: grid;
  min-height: fit-content;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 2px 4px #ccc;
  text-align: center;
}

button {
  width: 160px;
  height: 50px;
  margin: 4px;
  background-color: #FAC300;
  padding: 100px;
  border: none;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.buttonLink {
  text-decoration: none;
  color: inherit;
}

button:hover {
  background-color: #E5B800;
  box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2), 0 8px 30px 0 rgba(0,0,0,0.19);
  }

  .hintText {
    font-size: 12px;
    color: #697D91;
  }

.img-flag {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  background-color: #697D91;
  color: #FAC300;
  text-align: center;
}

@media (max-width: 1200px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }
  .card {
    margin-top: 15px;
  }
}